import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

const ProtectedRoute = (status) => {
  const Auth = { token: status && status ? status : false };

  return Auth.token ? <Outlet /> : <Navigate to="/" />;
};
export default ProtectedRoute;
